import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Highcharts from 'highcharts';
import axios from 'axios';

import { customSort, getApiServrUrl, getLastDateOfMonth, getGoogleAnalyticsId, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { lineColors } from '../../utils/constants';
import { DownloadButton } from '../common/download_button';
import Select from 'react-select';

import { observer } from 'mobx-react';
import { metadataStore } from '../../stores/metadata_store';
import { standardBenchmarkReportDataStore } from '../../stores/standard_benchmark_report_data_store';
import { dataVersionDateStore } from '../../stores/data_version_date_store';
import LoadingWithText from '../common/loading_text';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import Dropdown from '../common/collections_dropdown';
import { collectionsStore } from '../../stores/collections_store';
import { toJS } from 'mobx';
import { apiEventsStore } from '../../stores/api_events_store';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';
import GeoMap from './company_geo_map';
import GeoByDeptTableHC from './company_geo_table';


const CompanyGeoComponent = observer(() => {
    const [data, setData] = useState(null);
    const [requestData, setRequestData] = useState(null);
    const [collections, setCollections] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [isUnAuthorized, setIsUnAuthorized] = useState(false);
    const [connectivityError, setConnectivityError] = useState(false);
    const [dataVersionDate, setDataVersionDate] = useState(null);

    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;

    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`; 
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`;
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top-titles?company_id=${companyId}`;
    let geoPath = `/company/geo?company_id=${companyId}`;

    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
        geoPath += `&access_token=${accessToken}`;
    }

    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
        geoPath += `&dataset=${dataset}`;
    }

    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;
        geoPath += `&universe_id=${universeId}`;

        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };

    const getMetaData = async() => {
        // await both promises in parallel
        await Promise.all([
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ]);
        if (metadataStore.serverConnectionError) {
            // server connection error
            setConnectivityError(true);
            setIsMetaDataLoading(false);
        }
        else if (!metadataStore.isUnAuthorized) {
            setDataVersionDate(new Date(dataVersionDateStore.dataVersionDate));
            
            setCompanyName(metadataStore.data[companyId].companyName);
            if (metadataStore.data[companyId].publicProfileCount !== undefined) {
                setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
            }
        }
        setIsUnAuthorized(metadataStore.isUnAuthorized);
        setIsMetaDataLoading(false);
    };

    const getGeoData = async() => {
        
        try {
            const companyList = [
                {
                    "linkedin_company_id": parseInt(companyId),
                    "name": companyName
                }
            ];
            const dataVersionDateFormat = new Date(dataVersionDate);
            const endDate =`${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;

            setRequestData({
                "targetCompanyId": companyId,
                "targetCompanyName": companyName,
                companyList,
                endDate,
                dataset,
            });

            await standardBenchmarkReportDataStore.fetchGeoByDeptTypeForAllCompanies(companyId, companyList, endDate, dataset);
            const geoData = standardBenchmarkReportDataStore.geoByDeptData[companyId];

            setData(geoData);
            
            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                await collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId);
                setCollections(toJS(collectionsStore.collections[companyId]));
            };
           
            // setIsUnAuthorized(growthDataStore.setUnAuthorized);
        } catch (err) {
            console.log(err);
            if (err.response !== undefined && (err.response.status == 401 || err.response.status == 403)) {
                setIsUnAuthorized(true);
            }
            
        }

        setIsPageLoading(false);
    };


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);


    useEffect(() => {
        try {
            if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
                setIsMetaDataLoading(true);
                getMetaData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                setIsPageLoading(true);
                getGeoData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [publicProfileCount]);


    function handleTabClick(path) {
        let type = '';
        if (path.includes("team")) {
            type = "Team Growth";
        }
        else if (path.includes("company")) {
            type = "Company Growth";
        }
        else if (path.includes("talent")) {
            type = "Talent Flow";
        }
        else if (path.includes("tenure")) {
            type = "Tenure";
        }


        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Tenure",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Tenure",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
        }
        navigate(path);
    };

    if (connectivityError) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. The server connection is taking too long. Please try again later.</p>
            </div>
        );
    }
    else if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if ( publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={2000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        let loadingTexts = ['Fetching company geo data...', 'Setting up tables...', 'Adding final touches...'];
        
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px'}}/>
                        <div className="button" onClick={()=> {handleTabClick(companyGrowthPath)}}>
                            Company
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button" onClick={()=> {handleTabClick(teamGrowthPath)}}>
                            Teams
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button" onClick={()=> {handleTabClick(talentFlowPath)}}>
                            Talent Flow
                        </div>
                        <span style={{ margin: '5px'}}/>
                        {/* {
                            displayTalentRetention() && (
                                <div className="button">
                                    <a href={talentRetentionPath}>Talent Retention</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}}/>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}> Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        } */}
                        <div className="button" onClick={()=> {handleTabClick(tenurePath)}}>
                            Tenure
                        </div>
                        {/* <span style={{ margin: '5px'}}/>
                        <div className="button">
                        <a href={topTitlesPath}>Top Titles</a>
                        </div> */}
                        <span style={{ margin: '5px'}}/>
                        <div className="button active">
                            Geo
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                        { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Company Employee Dispersion: {companyName}
                    </h1>
                </div>
                { isPageLoading 
                ? (
                    <LoadingWithText texts={loadingTexts} interval={2000} />
                ) : 
                (
                    <div>
                        <GeoByDeptTableHC requestData={{
                                "targetCompanyId": parseInt(companyId),
                                "targetCompanyName": companyName,
                                "companyList": [
                                    {
                                        "linkedin_company_id": parseInt(companyId),
                                        "name": companyName
                                    }
                                ],
                                "endDate": `${dataVersionDate.getFullYear()}-${dataVersionDate.getMonth() + 1}-${dataVersionDate.getDate()}`,
                                dataset,
                            }} 
                        />
                        
                        <hr className="dotted-line" />
                        <GeoMap requestData={{
                                    "targetCompanyId": parseInt(companyId),
                                    "targetCompanyName": companyName,
                                    "companyList": [
                                        {
                                            "linkedin_company_id": parseInt(companyId),
                                            "name": companyName
                                        }
                                    ],
                                    "endDate": `${dataVersionDate.getFullYear()}-${dataVersionDate.getMonth() + 1}-${dataVersionDate.getDate()}`,
                                    dataset,
                                }} 
                        />
                        
                    
                    
                        <div style={{ margin:'0 auto', textAlign: 'center'}}>
                            <DownloadButton 
                                data={data} 
                                companyName={companyName} 
                                buttonText={ 'Download Geo Data' }
                                fileName={ `${companyName}_company_geo_data.csv` }
                                dataType={'companyGeo'}
                                accessToken={accessToken}
                            />
                        </div>
                        <div style={{ margin: '10px'}}>
                            Notes:
                            <ul>
                                <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                                <li>All data and analysis is based on publicly available profiles</li>
                            </ul>
                        </div>
                    </div> 
                )}
            </div>
        );
    }   
});

export default CompanyGeoComponent;
