import { toJS } from "mobx";
import { useEffect, useState } from "react";
import LoadingWithText from "../common/loading_text.js";
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
// Load the world map data
import worldMap from '@highcharts/map-collection/custom/world.topo.json';
import proj4 from 'proj4';
import { standardBenchmarkReportDataStore } from "../../stores/standard_benchmark_report_data_store.js";
import { countryCodesISO } from "../report/utils.js";


HighchartsMap(Highcharts);

// Add the map definition to Highcharts
Highcharts.maps['myMapName'] = worldMap;


const GeoMap = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);


    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.proj4 = proj4;
        }
      }, [])

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchGeoByDeptTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const geoByDeptData = standardBenchmarkReportDataStore.geoByDeptData[targetCompanyId];

            if (geoByDeptData !== null) {
                setData(toJS(geoByDeptData));
                console.log('geoByDeptData', toJS(geoByDeptData));
                setDataLoading(false);
            }
            else {
                console.log('geoByDeptData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {

        const drawGeoChart = (data) => {

            const processData = (data) => {
                const aggregatedData = {};

                data.forEach(item => {
                    if (item.geo3 && item.employee_count) {
                        if (!aggregatedData[item.geo3]) {
                          aggregatedData[item.geo3] = 0;
                        }
                        aggregatedData[item.geo3] += item.employee_count;
                    }
                });

                // Convert the aggregated data into the format Highcharts expects
                const seriesData = Object.entries(aggregatedData).map(([country, count]) => ({
                    name: country,
                    value: count,
                    code: countryCodesISO[country] || null // Use the ISO codes we defined earlier
                }));

                return seriesData;
            };

            const seriesData = processData(data);
            
            const values = data.map(item => item.employee_count_total);
            const minValue = Math.min(...seriesData.map(item => item.value));
            const maxValue = Math.max(...seriesData.map(item => item.value));

            Highcharts.mapChart('geo-map-chart-container', {
                chart: {
                    map: 'myMapName',
                    height: '35%',
                },
                // Add this projection configuration
                mapView: {
                    projection: {
                        name: 'Miller' // or 'EqualEarth', 'Mercator'
                    }
                },
                title: {
                    text: null
                },
                mapNavigation: {
                    enabled: false,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    }
                },
                colorAxis: {
                    min: minValue,
                    max: maxValue,
                    type: 'logarithmic',
                    stops: [
                        [0, '#ffffff'],
                        [0.3, '#9ba7df'],
                        [0.6, '#6884d9'],
                        [1, '#3563d0'],   
                    ]
                },
                tooltip: {
                    // headerFormat: '',
                    // pointFormat: '{point.name}: <b>{point.value:,.0f}</b> employees'
                    formatter: function() {
                        return this.point.name + ': <b>' + 
                               this.point.value.toLocaleString() + 
                               '</b> employees';
                    }
                },
                series: [{
                    data: seriesData,
                    joinBy: ['iso-a3', 'code'],
                    name: 'Employees',
                    states: {
                      hover: {
                        color: '#BADA55'
                      }
                    },
                    dataLabels: {
                      enabled: false,
                      format: '{point.name}'
                    }
                }],
                credits: {
                    enabled: false
                },
            });

        };

        if (data !== null
            && document.getElementById('geo-map-chart-container') !== null
        ) {
            const filteredData = data.filter((item) => item['company_id'] === targetCompanyId);
            console.log('filteredData', filteredData);
            drawGeoChart(filteredData);
        }

    }, [data]);

        return (
            <div>
                <div style={{textAlign: 'left', marginLeft: '5%'}}>
                    <h2> Employee Dispersion as of Current Month  </h2>
                </div>
                {
                    dataLoading ?
                    (
                        <div>
                            <LoadingWithText texts={['Requesting geo data...', 'Finished']} interval={5000} />
                        </div>
                    )
                    :
                    (
                    <>
                        <div id="geo-map-chart" className="chart-container">
                            <div className='chart-container-left-talent'>
                                <div id="geo-map-chart-container" />
                            </div>
                        </div>
                    </>
                    )
                }
            </div>
        );
};

export default GeoMap;
