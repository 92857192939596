import React from "react";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import moment from "moment";
import * as XLSX from 'xlsx';
import { getApiServrUrl, numberToCurrencyUSDFormatter } from "../../utils/utils"
import axios from "axios";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import { toJS } from "mobx";
import { amplitudeActions } from "../../utils/amplitude_util";


export const DownloadButton = (props) => {
    const buttonText = props.buttonText;
    const formatData = (propsObj) => {
        let data = propsObj.data;

        if (propsObj.dataType === 'companyGrowth') {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': propsObj.companyName,
                'Region': item.geo1,
                'Total Headcount (Public Profiles)': item.total_headcount,
                'Retained Headcount (Public Profiles)': item.retained_headcount,
                'Lost Headcount (Public Profiles)': item.lost_headcount,
                'New Headcount (Public Profiles)': item.new_headcount,
                'Net Headcount Growth (Last 12 Mo)': (item.ltm_net_hc_growth * 100).toFixed(1) + "%",
                'Attrition Rate (Last 12 Mo)': (item.ltm_attrition_rate * 100).toFixed(1) + "%",
                'Hiring Rate (Last 12 Mo)': (item.ltm_addition_rate * 100).toFixed(1) + "%",
                'Growth Efficiency (Last 12 Mo)': (item.ltm_growth_productivity * 100).toFixed(1) + "%",
                'Pct Female, Company Avg': (item.company_hc_total_avg_f_prob * 100).toFixed(1) + "%",
                'Pct Female, New Hires (Last 12 Mo)': (item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                'Pct Female, Attrits (Last 12 Mo)' : (item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                // add more formatting here if needed
            }));
        }
        if (propsObj.dataType === 'teamsGrowth') {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': propsObj.companyName,
                'Department': item.department,
                'Seniority': item.seniority,
                'Region': item.geo1,
                'Total Headcount (Public Profiles)': item.total_headcount,
                'Retained Headcount (Public Profiles)': item.retained_headcount,
                'Lost Headcount (Public Profiles)': item.lost_headcount,
                'New Headcount (Public Profiles)': item.new_headcount,
                'Net Headcount Growth (Last 12 Mo)': (item.ltm_net_hc_growth * 100).toFixed(1) + "%",
                'Attrition Rate (Last 12 Mo)': (item.ltm_attrition_rate * 100).toFixed(1) + "%",
                'Hiring Rate (Last 12 Mo)': (item.ltm_addition_rate * 100).toFixed(1) + "%",
                'Growth Efficiency (Last 12 Mo)': (item.ltm_growth_productivity * 100).toFixed(1) + "%",
                'Pct Female, Company Avg': (item.company_hc_total_avg_f_prob * 100).toFixed(1) + "%",
                'Pct Female, New Hires (Last 12 Mo)': (item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                'Pct Female, Attrits (Last 12 Mo)' : (item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                // add more formatting here if needed
            }));
        }

        if (propsObj.dataType === 'companyGeo') {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': propsObj.companyName,
                'Company ID': item.company_id,
                'Country': item.geo3,
                'Headcount': item.employee_count,
                'Department': item.dept_group,
                'Pct Overall': (item.pct_of_overall * 100).toFixed(1) + '%',
                'Pct of Dept': (item.pct_of_dept * 100).toFixed(1) + '%',
                // add more formatting here if needed
            }));
        }

        if (propsObj.dataType ==='universeSummary') {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': item.company_name,
                'Website': item.company_website,
                'LinkedIn URL': item.company_linkedin_url,
                'Industry': item.company_industry,
                'Headquarters Address': item.company_headquarters_address,
                'Headquarters Country': item.company_headquarters_country,
                'Age (Years)': item.company_age !== null ? item.company_age : 'N/A',
                'Employees (All)': item.company_employees_count_all !== null ? item.company_employees_count_all : 'N/A',
                'Employees (Public Profiles)': item.employees_count_public !== null ? item.employees_count_public : 'N/A',
                'Pct Public Profiles': item.pct_public_profiles !== null ? (item.pct_public_profiles * 100).toFixed(1) + '%': 'N/A',
                'Pct Female': item.current_avg_female_probability !== null ? (item.current_avg_female_probability * 100).toFixed(1) + '%': 'N/A',
                // 'Estimated Revenue': item.estimated_revenue !== null ? numberToCurrencyUSDFormatter(item.estimated_revenue) : 'N/A',
                'Net Headcount Growth (Last 12 Mo)': item.ltm_net_hc_growth !== null ? (item.ltm_net_hc_growth * 100).toFixed(1) + '%' : 'N/A',
                'Hiring Rate (Last 12 Mo)': item.ltm_hiring_rate !== null ? (item.ltm_hiring_rate * 100).toFixed(1) + '%' : 'N/A',
                'Attrition Rate (Last 12 Mo)': item.ltm_attrition_rate ?(item.ltm_attrition_rate * 100).toFixed(1) + '%': 'N/A',
                'Retention Rate (Last 12 Mo)': item.ltm_retention_rate !== null ?(item.ltm_retention_rate * 100).toFixed(1) + '%': 'N/A',
                'Growth Efficiency (Last 12 Mo)': item.ltm_growth_productivity !== null ?(item.ltm_growth_productivity * 100).toFixed(1) + '%': 'N/A',
                'Average Company Tenure (Years)': item.avg_company_tenure_years !== null ? item.avg_company_tenure_years.toFixed(1) : 'N/A',
                'Average Career Tenure (Years)': item.avg_career_tenure_years !== null ? item.avg_career_tenure_years.toFixed(1) : 'N/A',
            }));
        }

        // default return empty object
        return {};
    };

    const formatAndSaveTalentFlowData = (propsObj) => {
        let inflowData = propsObj.data.filter((item) => item['dataset'] === 'inflow');
        let outflowData = propsObj.data.filter((item) => item['dataset'] === 'outflow');
       
        const wb = XLSX.utils.book_new();

        const dateRangeText = 'Date Range: ' + propsObj.startMonth + ' ' + propsObj.startYear + ' to ' + propsObj.endMonth + ' ' + propsObj.endYear;
        const regions = props.regions;
        const departments = props.departments;
        const seniorities = props.seniorities;

        let regionText = 'Selected Regions: ';
        if (regions.length === 0) {
            // All regions are selected
            regionText += 'All';
        }
        else {
            for (let i = 0; i < regions.length; i++) {
                regionText += "'"+regions[i].value+"'";
                if (i < regions.length - 1) {
                    regionText += ', ';
                }
            }
        }

        let deptText = 'Selected Departments: ';
        if (departments.length === 0) {
            // All departments are selected
            deptText += 'All';
        }
        else {
            for (let i = 0; i < departments.length; i++) {
                deptText += "'"+departments[i].value+"'";
                if (i < departments.length - 1) {
                    deptText += ', ';
                }
            }
        }

        let seniorityText = 'Selected Seniorities: ';
        if (seniorities.length === 0) {
            // All seniorities are selected
            seniorityText += 'All';
        }
        else {
            for (let i = 0; i < seniorities.length; i++) {
                seniorityText += "'"+seniorities[i].value+"'";
                if (i < seniorities.length - 1) {
                    seniorityText += ', ';
                }
            }
        }
        
        

        // add inflow sheet
        const inflowFormattedData = inflowData.map(item => ({
            'Company Left': item.from_company_name,
            'Company Joined': item.to_company_name,
            // 'Month Joined': moment.utc(item.date_from).format('YYYY-MM-DD'),  // format date field
            // 'Region': item.geo1,
            'Number of People': item.employee_count,
        }));
        // const ws = XLSX.utils.json_to_sheet(inflowFormattedData);
        const ws = XLSX.utils.json_to_sheet([{}], {skipHeader: true}); // Create an empty row at the start
        XLSX.utils.sheet_add_json(ws, inflowFormattedData, {skipHeader: false, origin: "A7"}); // Start adding data from row 2
        XLSX.utils.sheet_add_json(ws, [{A: regionText}], {skipHeader: true, origin: "A1"}); // Intro sentence at A1
        XLSX.utils.sheet_add_json(ws, [{A: deptText}], {skipHeader: true, origin: "A2"}); // Intro sentence at A2
        XLSX.utils.sheet_add_json(ws, [{A: seniorityText}], {skipHeader: true, origin: "A3"}); // Intro sentence at A3
        XLSX.utils.sheet_add_json(ws, [{A: dateRangeText}], {skipHeader: true, origin: "A4"}); // Intro sentence at A4
        XLSX.utils.book_append_sheet(wb, ws, 'Talent Inflow');

        // add outflow sheet
        const outflowFormattedData = outflowData.map(item => ({
            'Company Left': item.from_company_name,
            'Company Joined': item.to_company_name,
            // 'Month Left': moment.utc(item.date_to).format('YYYY-MM-DD'),  // format date field
            // 'Region': item.geo1,
            'Number of People': item.employee_count,
        }));
        // const ws2 = XLSX.utils.json_to_sheet(outflowFormattedData);
        const ws2 = XLSX.utils.json_to_sheet([{}], {skipHeader: true}); // Create an empty row at the start
        XLSX.utils.sheet_add_json(ws2, outflowFormattedData, {skipHeader: false, origin: "A7"}); // Start adding data from row 2
        XLSX.utils.sheet_add_json(ws2, [{A: regionText}], {skipHeader: true, origin: "A1"}); // Intro sentence at A1
        XLSX.utils.sheet_add_json(ws2, [{A: deptText}], {skipHeader: true, origin: "A2"}); // Intro sentence at A2
        XLSX.utils.sheet_add_json(ws2, [{A: seniorityText}], {skipHeader: true, origin: "A3"}); // Intro sentence at A3
        XLSX.utils.sheet_add_json(ws2, [{A: dateRangeText}], {skipHeader: true, origin: "A4"}); // Intro sentence at A4
        XLSX.utils.book_append_sheet(wb, ws2, 'Talent Outflow');

        // Save as Excel file
        const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        saveAs(blob, propsObj.fileName);
    };

    const formatAndSaveTenureData = (propsObj) => {
        const data = propsObj.data;
        const wb = XLSX.utils.book_new();

        const regions = props.regions;
        const departments = props.departments;
        const seniorities = props.seniorities;

        let regionText = 'Selected Regions: ';
        if (regions.length === 0) {
            // All regions are selected
            regionText += 'All';
        }
        else {
            for (let i = 0; i < regions.length; i++) {
                regionText += "'"+regions[i].value+"'";
                if (i < regions.length - 1) {
                    regionText += ', ';
                }
            }
        }

        let deptText = 'Selected Teams: ';
        if (departments.length === 0) {
            // All departments are selected
            deptText += 'All';
        }
        else {
            for (let i = 0; i < departments.length; i++) {
                deptText += "'"+departments[i].value+"'";
                if (i < departments.length - 1) {
                    deptText += ', ';
                }
            }
        }

        let seniorityText = 'Selected Seniorities: ';
        if (seniorities.length === 0) {
            // All seniorities are selected
            seniorityText += 'All';
        }
        else {
            for (let i = 0; i < seniorities.length; i++) {
                seniorityText += "'"+seniorities[i].value+"'";
                if (i < seniorities.length - 1) {
                    seniorityText += ', ';
                }
            }
        }
         

        // add sheet
        const tenureFormattedData = data.map(item => ({
            'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
            'Company Name': propsObj.companyName,
            // 'Department': item.department,
            // 'Seniority': item.seniority,
            // 'Region': item.geo1,
            'Avg Career Tenure (Years)': item.avg_career_tenure_years !== null ? item.avg_career_tenure_years.toFixed(1) : 'N/A',
            'Avg Company Tenure (Years)': item.avg_company_tenure_years !== null ? item.avg_company_tenure_years.toFixed(1) : 'N/A',
            'Avg Career Tenure Female (Years)': item.avg_career_tenure_years_f !== null ? item.avg_career_tenure_years_f.toFixed(1) : 'N/A',
            'Avg Company Tenure Female (Years)': item.avg_company_tenure_years_f !== null ? item.avg_company_tenure_years_f.toFixed(1) : 'N/A',
            'Avg Career Tenure Male (Years)': item.avg_career_tenure_years_m !== null ? item.avg_career_tenure_years_m.toFixed(1) : 'N/A',
            'Avg Company Tenure Male (Years)': item.avg_company_tenure_years_m !== null ? item.avg_company_tenure_years_m.toFixed(1) : 'N/A',
            // add more formatting here if needed
        }));

        // const ws = XLSX.utils.json_to_sheet(inflowFormattedData);
        const ws = XLSX.utils.json_to_sheet([{}], {skipHeader: true}); // Create an empty row at the start
        XLSX.utils.sheet_add_json(ws, tenureFormattedData, {skipHeader: false, origin: "A6"}); // Start adding data from row 2
        XLSX.utils.sheet_add_json(ws, [{A: regionText}], {skipHeader: true, origin: "A1"}); // Intro sentence at A1
        XLSX.utils.sheet_add_json(ws, [{A: deptText}], {skipHeader: true, origin: "A2"}); // Intro sentence at A2
        XLSX.utils.sheet_add_json(ws, [{A: seniorityText}], {skipHeader: true, origin: "A3"}); // Intro sentence at A3
        XLSX.utils.book_append_sheet(wb, ws, 'Tenure Data');

        // Save as Excel file
        const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        saveAs(blob, propsObj.fileName);
    };

    const trackDownloadEvent = () => {
        // send API call to track download event
        try {
            let apiServerUrl = getApiServrUrl();
            apiServerUrl += process.env.REACT_APP_API_TRACK_DOWNLOAD_ENDPOINT;

            let data = {
                'type': props.dataType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.accessToken,
                },
                decompress: true,
                //withCredentials: true,
            };
            if (props.accessToken === null || props.accessToken === undefined || props.accessToken === '') {
                config['withCredentials'] = true;
            }

            // no need to wait for response, fire and forget
            axios.post(apiServerUrl, data, config);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleDownload = () => {
        // track download event
        trackDownloadEvent();
        
        if (props.dataType === 'talentFlow') {
            // save as excel file with two sheets
            formatAndSaveTalentFlowData(props);
        }
        else if (props.dataType === 'tenure') {
            // save as excel file
            formatAndSaveTenureData(props);
        }
        else {
            // save as csv file
            const formattedData = formatData(props);
            let csv = Papa.unparse(formattedData);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const filename = props.fileName;
            saveAs(blob, filename);
        }

        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Download", { type: props.dataType, user: toJS(userAccountDataStore.user) });
            amplitudeActions.track("Download", { type: props.dataType, user: toJS(userAccountDataStore.user) });
        }

    };

    // Utility function to convert string to array buffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    return (
        <button 
            className='button' 
            onClick={ handleDownload }
        >
                {buttonText}
        </button>
    );
};
