
import { PageLayout } from "../../components/common/page-layout"
import CompanyGeoComponent from "../../components/company/company_geo";

export const CompanyGeoPage = () => {
    return (
        <PageLayout>
            <CompanyGeoComponent />
        </PageLayout>
    );
};